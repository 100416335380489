<template>
  <ul
    class="list-disc ml-2"
  >
    <li
      v-for="material in items"
      :key="material"
    >{{ material }}</li>
  </ul>
</template>

<script>
export default {
  name: 'ListPackingMaterials',
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
}
</script>
